<template>
  <div class="error-page h-screen flex flex-col justify-center items-center">
    <img :src="data.url" alt="" />
    <n-button type="error" @click="backHome">返回首页</n-button>
  </div>
</template>

<script setup lang="ts">
import err404 from "@/assets/images/default_404.svg";
import errDefault from "@/assets/images/default_function.svg";

const err = useError();
const data = reactive({
  url: errDefault,
});

if (err.value?.statusCode) {
  switch (err.value?.statusCode) {
    case "404":
      data.url = err404;
      break;

    default:
      data.url = errDefault;
      break;
  }
}

// console.log("错误信息", err.value);
// console.log("错误信息", err.value.message);
// console.log("错误信息", err.value.statusCode);

// 返回首页
function backHome() {
  clearError({ redirect: "/" });
}
</script>

<style lang="less" scoped>
.error-page {
  img {
    width: 380px;
  }
  p {
    margin-bottom: 20px;
  }
}
</style>
